/**@jsx jsx */
import { graphql } from 'gatsby'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useTheme } from '@emotion/react'
import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  jsx,
  Text,
  useColorMode,
  Label,
} from 'theme-ui'
import { useForm } from 'react-hook-form'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import StyledWrapper from '~/components/Generic/StyledWrapper'
import { WigglySectionDivider } from '~/components/Generic/SectionDivider/WigglySectionDivider'
import { MobileWigglySectionDivider } from '~/components/Generic/SectionDivider/MobileWigglySectionDivider'
import ImageAndTextSection from '~/components/Product/Page/ImageAndTextSection'

const Beta = ({ data }) => {
  const { page } = data || {}
  const { hero } = page || {}
  const theme = useTheme()
  const [, setColorMode] = useColorMode()
  const [isError, setIsError] = useState(false)
  const translate = useTranslate()
  const { register, handleSubmit, reset } = useForm()
  //run on mount
  useEffect(() => {
    setColorMode('cheddy-mac')
  }, [])

  function betaOptIn() {
    const now = new Date()
    const expires = now.getTime() + 1000 * 3600 * 24 * 365
    now.setTime(expires)

    document.cookie = `nf_ab=${
      process.env.GATSBY_CURRENT_BETA_BRANCH
    }; expires=${now.toUTCString()};path=/`
    window.location.reload(true)
  }

  function betaOptOut() {
    // clear and expire the cookie.
    document.cookie = 'nf_ab=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'

    // reload the page to pick up the new option.
    window.location.reload(true)
  }
  const userIsInBeta = process.env.GATSBY_IS_BETA_BRANCH === 'true'

  const onSubmit = async event => {
    setIsError(false)
    try {
      const res = await fetch(
        `/.netlify/functions/check-beta-password/?accessCode=${event.password}`,
      )

      if (res.status === 200) betaOptIn()
      else {
        setIsError(true)
        reset()
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (!data?.page) return null

  return (
    <Layout>
      <Metadata title={page?.name} />
      <Container
        sx={{
          height: '50vh',
          my: '5rem',
          maxWidth: ['100%'],
          mx: ['auto', 0],
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'start',
          position: 'relative',
        }}
      >
        {hero && (
          <ImageAndTextSection
            gatsbyImageData={hero?.image?.gatsbyImageData}
            headingText={hero?.heading?.heading}
            subHeadingText={hero?.subheading}
            containerStyle={{
              display: ['flex'],
              color: 'secondary',
              maxWidth: 1440,
              width: ['auto', (626 + 50) * 2],
              zIndex: 2,
              ' > div': { p: 0 },
              my: 0,
              '.fade-image-hero': {
                display: ['none', 'none', 'initial'],
                maxWidth: '400px',
                overflow: 'hidden',
                height: 'auto',
              },
            }}
          />
        )}

        <StyledWrapper>
          {!userIsInBeta && (
            <Flex
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                flexDirection: 'column',
                mt: '1rem',
                color: 'tertiary',
                flexGrow: 1,
                maxWidth: '500px',
                mx: ['auto', 0],
                position: 'relative',
                zIndex: 10,
              }}
            >
              <Flex mb="2" sx={{ flexDirection: 'column' }}>
                <Label variant="text.formLabel" mb="1" htmlFor="password">
                  {translate('beta.password_label')}
                </Label>
                <Input
                  variant="forms.input"
                  {...register('password')}
                  type="password"
                  sx={{ mb: '1rem', borderWidth: '2px', background: 'white' }}
                />

                <Button
                  type="submit"
                  sx={{
                    width: '100%',
                  }}
                >
                  {translate('beta.join')}
                </Button>
              </Flex>
              {isError && (
                <Text sx={{ color: 'error' }}> {translate('beta.error')}</Text>
              )}
            </Flex>
          )}
          {userIsInBeta && (
            <Button
              sx={{
                mt: '2rem',
                mx: ['auto', 0],
                maxWidth: '500px',
                width: '100%',
                position: 'relative',
                zIndex: 5,
              }}
              onClick={betaOptOut}
            >
              {translate('beta.opt-out')}
            </Button>
          )}
        </StyledWrapper>
      </Container>
      <WigglySectionDivider
        topColor={theme.colors.primary}
        bottomColor={theme.colors.grape}
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-1px',
          display: ['flex', 'none'],
        }}
        small
        topColor={theme.colors.grape}
        bottomColor={theme.colors.primary}
      />
      <Box
        sx={{
          height: '300px',
          background: theme.colors.grape,
        }}
      ></Box>
    </Layout>
  )
}

export const query = graphql`
  query Query($locale: String) {
    page: contentfulPage(slug: { eq: "beta" }, node_locale: { eq: $locale }) {
      ...ContentHeroFragment
      ...ContentPageFragment
    }
  }
`

export default Beta
